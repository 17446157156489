<template>
  <div class="mainbox">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <h1>修改密码</h1>
      </div>
      <!-- ref相当于是给了个id， 获取的话 this.$refs.名字 -->
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="myForm"
      >
        <el-form-item label="当前密码" prop="pass">
          <el-input v-model="ruleForm.pass" autocomplete="off" ></el-input>
        </el-form-item>

        <el-form-item label="新密码" prop="newpass">
          <el-input v-model="ruleForm.newpass" autocomplete="off" show-password></el-input>
        </el-form-item>

        <el-form-item label="确认新密码" prop="checkPass">
          <el-input v-model="ruleForm.checkPass" autocomplete="off" show-password></el-input>
        </el-form-item>

        <!-- 按钮 -->
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>

      </el-form>
    </el-card>
  </div>
</template>

<script>
  export default {
    data() {
      
      var validatePass = (rule, value, callback) => {
          //value:当前文本框内的值
          //callback：回调函数，callback中显示的文字，最后就会变为错误提示显示在文本框的下方
         var reg = /^\w{4,16}$/ 
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
            if(reg.test(value)){
                callback();
            }else{
                callback(new Error('密码只能为4-16位数字字母下划线'))
            }
        }
      };

      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.ruleForm.newpass) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      return {
        ruleForm: {
          pass: '',
          newpass:'',
          checkPass: '',
        },
        rules: {
          pass: [
            { validator: validatePass, trigger: 'blur' ,required: true,}
          ],
          newpass: [
            { validator: validatePass, trigger: 'blur' ,required: true,}
          ],
          checkPass:[
            { validator: validatePass2, trigger: 'blur',required: true, }
          ]
        }
      };
    },
    methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            alert('submit!');
          } else {
             this.$message.error('错了哦，请输入格式正确的密码');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    }
  }
</script>

<style lang="less" scoped>
    .mainbox{
        padding: 2rem;
        .box-card{
            .clearfix{

            }
            .myForm{
                
            }
        }
    }
</style>